import React, { useEffect, useState } from 'react'
import { Affix, Button, Card, Col, Form, Row, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AlertMessages, FileUploadForm, StatusTag, useAuthState } from '../../../../common';
import { useIntl } from 'react-intl';
import { BargeAgreementService, ContractStatusEnum, PreviewRequests, VesselAgreementPreviewDto } from '@exportx/shared-models-and-services';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getFilesData } from '@exportx/ui-utils';
import queryString from 'query-string';
import Link from 'antd/lib/typography/Link';

const BargeAgreementDetailedView = () => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  //const { baId } = state ? state : { baId: null };
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.ba_Id;
  console.log(paramBargeId, "=====================")
  console.log(queryString.parse(location.search));
  let { baId } = paramBargeId != null ? { baId: paramBargeId } : state ? state : { baId: null };

  const [gridData, setGridData] = useState<any>();
  const service = new BargeAgreementService();

  const [filesFormRef] = Form.useForm();
  const { authContext } = useAuthState();

  useEffect(() => {
    if (baId) {
      getBargeAgreementPreviewData();
    }

  }, [baId])

  const getBargeAgreementPreviewData = () => {
    const req = new PreviewRequests(baId, authContext.defaultPlant);
    service.getBargeAgreementPreviewData(req).then(res => {
      if (res.status) {
        setGridData(res.data);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const sendForApproval = () => {
    const req = new PreviewRequests(baId, authContext.defaultPlant);
    service.sendForApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Barge Agreement Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
    const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status)
    service.approveOrRejectAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getBargeAgreementPreviewData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const addendumClickHandler = () => {
    // const link = `${vesselAddendum}?ba_Id=${baId}`
    // navigate(link, { state: { record: gridData } })
  }

  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button onClick={() => sendForApproval()}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <span>
        <Button size="small" onClick={() => {
          approveOrRejectHandler(baId, ContractStatusEnum.ACTIVE);
        }}>Approve</Button> &nbsp;&nbsp;
        <Button type='dashed' size="small" onClick={() => {
          approveOrRejectHandler(baId, ContractStatusEnum.REJECT);
        }}>Reject</Button>
      </span>
    }
    if (status === ContractStatusEnum.ACTIVE) {
      return <Button onClick={() => addendumClickHandler()}>Create Addendum</Button>
    }
  }


  const setFormData = () => {
    navigate('/bargeAgreement-update', { state: { record: gridData } });
  }



  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
        service.addMoreBargeAgreementFileData({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          baId: baId
        }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            getBargeAgreementPreviewData();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }




  const bargeDetails: ColumnsType<any> = [
    {
      title: 'Barge Vendor',
      dataIndex: 'vendorName',
      // render: (value, record) => {
      //   const link = `/#/detailCustomer?bp_id=${record.bargeVendorId}`
      //     return<> {
      //     <Link href={link} className="link-primary">{value}</Link>
      //   }
      //   </>}


    },

    {
      title: 'Currency',
      dataIndex: 'currency',


    },
    {
      title: 'Agreement Date',
      dataIndex: 'agreementDate',
      render: (text, record) => { return record?.agreementDate ? moment(record?.agreementDate).format('YYYY-MM-DD') : "" }

    },

  ];
  const specPriceData: ColumnsType<any> = [
    {
      title: 'Area',
      dataIndex: 'area',


    },

    {
      title: 'Price',
      dataIndex: 'price',


    },
    {
      title: 'Barging Type',
      dataIndex: 'bargingType',


    },
    {
      title: 'VAT Applicability (PPN)',
      dataIndex: 'vatInclOrExcl',


    },
  ];
  const remarks: ColumnsType<any> = [
    {
      title: 'Remarks',
      dataIndex: 'remarks',

      render: text => <span>{text}</span>,
    },]


  return (
    <div >
      <Card
        style={{ textAlign: 'center' }}
        className='default-card-class'
        title={<span style={{ color: 'white' }}> Contract -  {(gridData?.fixtureNoteNo)}
          <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
          </span>
        </span>
        }
        extra={<>{getExtraComponent(gridData?.status)}</>}
      >
        <Card >
          <Table dataSource={Array(gridData)} columns={bargeDetails} pagination={false} bordered /><br></br>
          <Table dataSource={gridData?.specPriceData ? gridData?.specPriceData : gridData} columns={specPriceData} pagination={false} bordered /><br></br>
          <Table dataSource={Array(gridData)} columns={remarks} pagination={false} bordered />
        </Card>
        {gridData?.filesData &&
          <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={fileUploadFormHandler}
                      type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </FileUploadForm>
        }

        <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col><Button onClick={setFormData}>Edit</Button></Col>
            </Row>
          </Card>
        </Affix>
      </Card>


    </div>
  )
}

export default BargeAgreementDetailedView
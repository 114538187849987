import { DeleteFilled, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { BNoRequest, BPDropDownDto, BpCategoryGetDto, BusinessNumberService, BusinessPartnerService, BusinessPartnerTypeEnum, DestinationService, CurrencyService, PriceTypeEnum, FileHandlingService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { getBase64, getFilesData } from '@exportx/ui-utils';
import { Button, Col, Form, Input, InputNumber, Row, Select, Table, Tooltip, Upload, UploadFile, UploadProps, message, Radio, Space } from 'antd';
import Card from 'antd/es/card/Card';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';
import ViewFileHandling from '../../../../common/file-upload-form/view-files-handling';
import DatePicker from '../../../../common/data-picker/date-picker';
import { ClassValidator } from '@exportx/ui-utils';
import { FileUploadForm } from '../../../../common';

interface IBNCommercialPageProps {
    cancelHandler: () => void;
    submitHandler: (values: any) => void;
    businessNo?: string;
}
const { Option } = Select;
const classValidator = new ClassValidator();
export const BNCommercialPage = (props: IBNCommercialPageProps) => {
    const [initialValues, setInitialValues] = useState({ bnCmdId: '', coo: [{ bnCooId: '', cooFiles: [] }], billOfLading: [{ bnBlOfLadingId: '' }], exportTax: [{ bnExportxId: '' }], cmDocuments: [], cooFiles: [], exportTaxFiles: [], billFiles: [] });
    const [formRef] = Form.useForm();
    const [pkkaformRef] = Form.useForm();
    const [rkbmformRef] = Form.useForm();
    const [izinformRef] = Form.useForm();
    const [fileList, setFileList] = useState<any[]>([]);
    const [cooFileList, setCooFileList] = useState<any[]>([]);
    const [blFileList, setBLFileList] = useState<any[]>([]);
    const [pebFileList, setPEBFileList] = useState<any[]>([]);
    const [suppliers, setSuppliers] = useState<BPDropDownDto[]>([])
    const [destination, setDestination] = useState<any[]>()
    const { cancelHandler, businessNo } = props;
    const { formatMessage: fm } = useIntl();
    const businessPartnerService = new BusinessPartnerService();
    const destinationService = new DestinationService()
    const bnService = new BusinessNumberService();
    const fileService = new FileHandlingService();
    const currencyService = new CurrencyService();
    const { authContext } = useAuthState();
    const [detailedView, setDetailedView] = useState<boolean>(true)
    const [tableData, setTableData] = useState<any>();
    const [currencies, setCurrencies] = useState<any[]>([]);
    const [vendorsData, setVendorsData] = useState<BPDropDownDto[]>([]);
    const [priceTypes,setPriceType] = useState<any>({});
    const pkkaFilesData = Form.useWatch('filesData', pkkaformRef)
    const rkbmFilesData = Form.useWatch('filesData', rkbmformRef)
    const izinFilesData = Form.useWatch('filesData', izinformRef)


    useEffect(() => {
        // getAllBusinessPartnersVendorsDropDown();
        getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
        destinationDropDown();
        getCurrencyData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getAllCommercialDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessNo]);

    const getAllCommercialDocuments = () => {
        const req = new BNoRequest(businessNo, authContext.defaultPlant)
        bnService.getAllCommercialDocuments(req).then(res => {
            if (res.status) {
                setInitialValues(res.data);
                setTableData(res.data);
            }
        }).catch(err => console.log(err.message));
    }

    useEffect(() => {
        if (initialValues) {
            const billOfLading = [...initialValues.billOfLading];
            billOfLading.map((rec: any, index) => {
                return initialValues.billOfLading[index] = { ...rec, blDate: moment(rec?.blDate) }
            });
            const exportTax = [...initialValues.exportTax];
            exportTax.map((rec: any, index) => {
                return initialValues.exportTax[index] = { ...rec, pebDate: moment(rec?.pebDate) }
            });
            formRef.setFieldsValue(initialValues);
            // coo files Id FOr update
            for (let i = 0; i < initialValues.coo.length; i++) {
                const thirdPartyFileId = tableData?.coo?.filter((rec) =>
                    initialValues?.coo[i]?.cooFiles.some((rec2) =>
                        rec2.featuresRefId === rec.bnCooId))[0]?.cooFiles?.filter((rec) =>
                            rec.fileDescription.split(' ')[0] === "Third")[0]?.fileUploadId;
                const uploadDocumentId = tableData?.coo?.filter((rec) =>
                    initialValues?.coo[i]?.cooFiles.some((rec2) =>
                        rec2.featuresRefId === rec.bnCooId))[0]?.cooFiles?.filter((rec) =>
                            rec.fileDescription.split(' ')[0] === "Uploaded")[0]?.fileUploadId;
                formRef.setFieldValue(['coo', i, 'thirdPartyInvoiceFileId'], thirdPartyFileId);
                formRef.setFieldValue(['coo', i, 'uploadCooDocumentId'], uploadDocumentId);
            };
            // bill of lading fileIds
            tableData?.billOfLading.map((rec, index) => {
                formRef.setFieldValue(['billOfLading', index, "bnBlOfLadingFileId"], rec.billFiles[0]?.fileUploadId)
            });
            // exportTax fileIds
            tableData?.exportTax.map((rec, index) => {
                formRef.setFieldValue(["exportTax", index, "bnExportxFileId"], rec.exportTaxFiles[0]?.fileUploadId)
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const validateFileType = ({ type, name }: UploadFile, allowedTypes: string[] = ['image/jpeg']) => {
        return true;//allowedTypes.includes(type!);
    };
    const getUploadProps = (indexOfThatFile: number, stateVariableSetter: React.Dispatch<React.SetStateAction<any[]>>, stateVariable: any[]) => {
        const uploadProps = {
            multiple: false,
            beforeUpload: (file: UploadFile) => {
                const isAllowedType = validateFileType(file);
                if (!isAllowedType) {
                    message.error(`${file.name} is not JPEG file`);
                    return false;
                }
                stateVariableSetter((prev) => {
                    if (prev.length < 5) {
                        const clonedData = [...prev];
                        clonedData[indexOfThatFile] = file;
                        return clonedData;
                    }
                    return prev;
                });
                return false;
            },
            onRemove: (file: UploadFile) => {
                stateVariableSetter((prev) => {
                    const clonedData = [...prev];
                    clonedData[indexOfThatFile] = [];
                    return clonedData;
                });
            },
            fileList: stateVariable[indexOfThatFile] ? [stateVariable[indexOfThatFile]] : []
        } as UploadProps;
        return uploadProps;
    };

    const uploadButton = (
        <div>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </div>
    );

    const filesHandler = async (values: any) => {
        values.filesList = [];
        let pkBase64;
        let PKKAFile;
        if (values?.PKKAFile) {
            pkBase64 = await getBase64(values?.PKKAFile[0]?.originFileObj);
            PKKAFile = values?.PKKAFile[0]
        };
        let rkbmFileBase64;
        let RKBMFile;
        if (values?.RKBMFile) {
            rkbmFileBase64 = await getBase64(values?.RKBMFile[0]?.originFileObj);
            RKBMFile = values?.RKBMFile[0]
        };
        let izinFileBase64;
        let IZINFile;
        if (values?.IZINFile) {
            izinFileBase64 = await getBase64(values?.IZINFile[0]?.originFileObj);
            IZINFile = values?.IZINFile[0]
        }
        const pk = [];
        const rkbm = [];
        const izin = [];

        const allFilesValues = [PKKAFile, RKBMFile, IZINFile, values?.coo, values?.billOfLading, values?.exportTax]
        for (let i = 0; i < allFilesValues.length; i++) {
            if (i === 0) {
                if (PKKAFile) {
                    const fileUploadId = initialValues?.cmDocuments.filter((rec) => rec.fileDescription === "PKKA FILE")[0]?.fileUploadId;
                    pk.push({ ...allFilesValues[0], base64Url: pkBase64, fileDescription: 'PKKA FILE', fileUploadId: fileUploadId ? fileUploadId : undefined });
                }
            };
            if (i === 1) {
                if (RKBMFile) {
                    const fileUploadId = initialValues?.cmDocuments.filter((rec) => rec.fileDescription === "RKBMFile FILE")[0]?.fileUploadId;
                    rkbm.push({ ...allFilesValues[1], base64Url: rkbmFileBase64, fileDescription: 'RKBMFile FILE', fileUploadId: fileUploadId ? fileUploadId : undefined });
                }
            };
            if (i === 2) {
                if (IZINFile) {
                    const fileUploadId = initialValues?.cmDocuments.filter((rec) => rec.fileDescription === "RKBMFile FILE")[0]?.fileUploadId;
                    izin.push({ ...allFilesValues[2], base64Url: izinFileBase64, fileDescription: 'IZINFile FILE', fileUploadId: fileUploadId ? fileUploadId : undefined });
                }
            };
            if (i === 3) {
                let count = 1;

                for (const coo of values.coo) {
                    if (coo?.thirdPartyInvoiceFile) {
                        const thirdPartyBase64 = await getBase64(coo?.thirdPartyInvoiceFile?.file);
                        coo.thirdPartyInvoiceFile = {
                            ...coo.thirdPartyInvoiceFile,
                            base64Url: thirdPartyBase64,
                            fileDescription: `Third Party File ${count}`,
                            fileUploadId: coo.thirdPartyInvoiceFileId
                        };
                    };
                    if (coo?.uploadCooDocument?.file) {
                        const uploadCooDocumentBase64 = await getBase64(coo?.uploadCooDocument?.file);
                        coo.uploadCooDocument = {
                            ...coo.uploadCooDocument,
                            base64Url: uploadCooDocumentBase64,
                            fileDescription: `Uploaded Document ${count}`,
                            fileUploadId: coo.uploadCooDocumentId
                        };
                    };

                    count += 1;
                };
            };
            if (i === 4) {
                let count = 1;
                for (const bill of values.billOfLading) {
                    if (bill?.upLoadBlDocument) {
                        const billBase64 = await getBase64(bill?.upLoadBlDocument?.file);
                        bill.upLoadBlDocument = {
                            ...bill.upLoadBlDocument,
                            base64Url: billBase64,
                            fileDescription: `Bill Of Lading File ${count}`,
                            fileUploadId: bill.bnBlOfLadingFileId
                        }
                    };
                    count += 1;
                };
            };
            if (i === 5) {
                let count = 1;
                for (const ex of values.exportTax) {
                    if (ex?.upLoadPebDocument) {
                        const exportxBase64 = await getBase64(ex?.upLoadPebDocument?.file);
                        ex.upLoadPebDocument = {
                            ...ex.upLoadPebDocument,
                            base64Url: exportxBase64,
                            fileDescription: `Export Tax File ${count}`,
                            fileUploadId: ex.bnExportxFileId
                        }
                    };
                    count += 1;
                };
            }

        }
        values.filesList.push({ PKKAFile: pk, RKBMFile: rkbm, IZINFile: izin })
        return values.filesList;

    };

    const getCurrencyData = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
          if (res.status) {
            setCurrencies(res.data);
          }
        }).catch(err => {
          console.log('error: ' + err.message);
        })
      }

    const suffixSelector = (path: number, name: string) => (
        <Form.Item name={[path, name]} noStyle label="Please Select">
          <Select
            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            allowClear showSearch style={{ width: 70 }}>
            {currencies.map(item => {
              return <Option value={item.currencyCode}>{item.currencyName}</Option>
            })}
          </Select>
        </Form.Item>
      );
    


    const submitButtonHandler = () => {
        formRef.validateFields().then(async values => {
            let pkkaFiles = [];
            await pkkaformRef.validateFields().then(async pkkaValues => {
                await getFilesData(pkkaValues.filesData).then(filesData => {
                    pkkaFiles = filesData;
                    pkkaFiles.map((file) => file.referenceFeatureName = 'Business No PKKA')
                });
            })
            let rkbmFiles = [];
            await rkbmformRef.validateFields().then(async rkbmValues => {
                await getFilesData(rkbmValues.filesData).then(filesData => {
                    rkbmFiles = filesData;
                    rkbmFiles.map(file => file.referenceFeatureName = 'Business No RKBM')
                  });
            })
            let izinFiles = [];
            await izinformRef.validateFields().then(async izinValues => {
                await getFilesData(izinValues.filesData).then(filesData => {
                    izinFiles = filesData;
                    izinFiles.map(file => file.referenceFeatureName = 'Business No IZIN'  )
                  });
            })
            const filesList2 = [...pkkaFiles, ...rkbmFiles, ...izinFiles];
            const filesList = await filesHandler(values);
            bnService.createBnCommercialDocument({ ...values, filesData: filesList2, businessNo: businessNo, plantCode: authContext.defaultPlant }).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    getAllCommercialDocuments();
                    setDetailedView(true);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err.message);
            })

        })
    }

    const addNewFiles = async (fileFormRef, referenceFeatureName, initialValues) => {
        await fileFormRef.validateFields().then( async values => {
            await getFilesData(values.filesData).then(filesData => {
                    fileService.addNewFiles({filesData: filesData, bnCmdId: initialValues.bnCmdId, referenceFeatureName,createdUser: authContext.user.userName}).then((res)=>{
                    if (res.status) {
                        AlertMessages.getSuccessMessage(res.internalMessage);
                        getAllCommercialDocuments();
                        fileFormRef.resetFields();
                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage);
                    }
                }).catch(err => {
                    console.log(err.message);
                })
              });
        })
    }

    const handlePriceType = (e, field) => {
        let priceType = e.target.value;
        setPriceType(prevState => ({
                    ...prevState,
                [field.name]: priceType,
        }));
    }

    const getAllBusinessPartnersForDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
        businessPartnerService.getAllBusinessPartnersForDropDown(bpCategoryGetDto).then(res => {
          if (res.status) {
            if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
              setVendorsData(res.data);
          }
        }).catch(err => {
          console.log('error: ' + err.message);
        })
      };

    const destinationDropDown = () => {
        destinationService.getDestinationDropDown().then(res => {
            if (res.status) {
                setDestination(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const columnsCoo = [
        {
            title: 'COO Number',
            dataIndex: 'cooNo'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty'
        },
        {
            title: 'Price',
            dataIndex: 'price'
        },
        {
            title: 'Port Of Discharge',
            dataIndex: 'destinationName'
        },
        {
            title: 'Upload Document',
            dataIndex: 'cooFiles',
            render: (values) => {
                const file = values.filter((rec) => rec.fileDescription.split(' ')[0] === "Uploaded");
                return <>
                    <ViewFileHandling filesData={file} required={true} />
                </>
            }
        },
        {
            title: 'Third Party Invoice File',
            dataIndex: 'cooFiles',
            render: (values) => {
                const file = values.filter((rec) => rec.fileDescription.split(' ')[0] === "Third");
                return <>
                    <ViewFileHandling filesData={file} required={true} />
                </>
            }
        }
    ];

    const columnsBill = [
        {
            title: 'BL No',
            dataIndex: 'blNo'
        },

        {
            title: 'BL Qty',
            dataIndex: 'blQty'
        },
        {
            title: 'BL Date',
            render: (value) => {
                return <>
                    {moment(value).format('YYYY-MM-DD')}
                </>
            }
        },
        {
            title: 'UploadDocument',
            dataIndex: 'billFiles',
            render: (value, record, index) => {
                return <>
                    <ViewFileHandling filesData={value} required={true} />
                </>
            }
        },


    ];
    console.log(initialValues, "44444444444444444")
    console.log(tableData, "tablesData")
    const columnsExportTax = [
        {
            title: 'Supplier',
            dataIndex: 'bpName'
        },

        {
            title: 'PEB No',
            dataIndex: 'pebNo'
        },
        {
            title: 'PEB Date',
            dataIndex: 'pebDate',
            render: (value, record) => {
                return <>
                    {moment(value).format('YYYY-MM-DD')}
                </>
            }
        },
        {
            title: 'ID Billing',
            dataIndex: 'idBilling'
        },
        {
            title: 'Qty',
            dataIndex: 'quantity'
        },
        {
            title: 'Export Tax Per Mt',
            dataIndex: 'exportTaxPerMt'
        },
        {
            title: 'Export Tax Per Mt',
            dataIndex: 'exportTaxPerMt'
        },
        {
            title: 'Upload Document',
            dataIndex: 'exportTaxFiles',
            render: (value, record, index) => {
                return <>
                    <ViewFileHandling filesData={value} required={true} />
                </>
            }
        },

    ];
    const columnsNotifyParty = [
        {
            title: 'Name',
            dataIndex: 'partyName'
        },

        {
            title: 'Phone No',
            dataIndex: 'phoneNo'
        },
        {
            title: 'Address',
            dataIndex: 'partyAddress',
        },
    ];

    const columnsInsurance = [
        {
            title: 'Insurance Vendor Name',
            dataIndex: 'bpName'
        },
        {
            title: 'Policy Number',
            dataIndex: 'policyNumber'
        },
        {
            title: 'Price Type',
            dataIndex: 'priceType',
        },
        {
            title: 'Insurance Amount',
            dataIndex: 'insuranceAmount',
            render: (text, record) => {
                if (record.priceType === PriceTypeEnum.LUMP_SUM) {
                    return record.insurancePrice && record.priceCurrency
                        ? `${record.insurancePrice} ${record.priceCurrency}`
                        : null;
                } else if (record.priceType === PriceTypeEnum.PER_MT) {
                    return record.insuranceAmount && record.amountCurrency
                        ? `${record.insuranceAmount} ${record.amountCurrency}`
                        : null;
                }
                return null;
            }
        }
     
    ];

    const columnsRemarks = [
        {
            title: 'Remarks',
            dataIndex: 'remarks'
        },



    ];



    return <>
        {detailedView === true &&

            <>
                <Card title='Commercial Documents'>
                    {/* {initialValues?.cmDocuments.length !== 0 &&
                        <Card title='Documents'>
                            <ViewFileHandling filesData={initialValues?.cmDocuments} required={true} />
                        </Card>
                    } */}
                        <Col style={{marginBottom: '16px'}}>
                            { initialValues.bnCmdId !== '' ? 
                            <FileUploadForm key={'pkka'} maxCount={3} layoutType='vertical' formRef={pkkaformRef} initialValues={initialValues.cmDocuments ? initialValues.cmDocuments.filter(doc => doc.featuresRefName === 'Business No PKKA') : []} title='PKKA Documents'>
                                <Row justify="end">
                                    <Col>
                                        {<Space>
                                            {(pkkaFilesData && pkkaFilesData.length > 0) && (<Button type="primary" onClick={() => { addNewFiles(pkkaformRef, 'Business No PKKA',initialValues); }}>
                                                Save
                                            </Button>)}
                                        </Space>}
                                    </Col>
                                </Row>
                            </FileUploadForm>
                            : <Card title='PKKA Documents'></Card>}
                        </Col>
                        <Col style={{marginBottom: '16px'}}>
                        { initialValues.bnCmdId !== '' ? 
                            <FileUploadForm key={'rkbm'} maxCount={3} layoutType='vertical' formRef={rkbmformRef} initialValues={initialValues.cmDocuments ? initialValues.cmDocuments.filter(doc => doc.featuresRefName === 'Business No RKBM') : []} title='RKBM Documents' >
                                <Row justify="end">
                                    <Col>
                                        {<Space>
                                            {(rkbmFilesData && rkbmFilesData.length > 0) && (<Button type="primary" onClick={() => { addNewFiles(rkbmformRef, 'Business No RKBM',initialValues); }}>
                                                Save
                                            </Button>)}
                                        </Space>}
                                    </Col>
                                </Row>
                            </FileUploadForm>
                            : <Card title='RKBM Documents'></Card>}
                        </Col>
                        <Col style={{marginBottom: '16px'}}>
                            { initialValues.bnCmdId !== '' ? 
                            <FileUploadForm key={'izin'} maxCount={3} layoutType='vertical' formRef={izinformRef} initialValues={initialValues.cmDocuments ? initialValues.cmDocuments.filter(doc => doc.featuresRefName === 'Business No IZIN') : []} title='IZIN Documents' >
                                <Row justify="end">
                                    <Col>
                                        {<Space>
                                            {(izinFilesData && izinFilesData.length > 0) && (<Button type="primary" onClick={() => { addNewFiles(izinformRef, 'Business No IZIN',initialValues); }}>
                                                Save
                                            </Button>)}
                                        </Space>}
                                    </Col>
                                </Row>
                            </FileUploadForm>
                            : <Card title='IZIN Documents'></Card>}
                        </Col>
                    <Card title='COO'>
                        <Table pagination={false} columns={columnsCoo} dataSource={tableData?.coo} bordered />
                    </Card >
                    <Card title='Bill oF Lading'>
                        <Table pagination={false} columns={columnsBill} dataSource={tableData?.billOfLading} bordered />

                    </Card >
                    <Card title='Export Tax'>
                        <Table pagination={false} columns={columnsExportTax} dataSource={tableData?.exportTax} bordered />

                    </Card>
                    <Card title='Notify Party'>
                        <Table pagination={false} columns={columnsNotifyParty} dataSource={tableData?.notifyParty} bordered />
                    </Card>
                    <Card title='Insurance'>
                        <Table pagination={false} columns={columnsInsurance} dataSource={tableData?.insurance} bordered />
                    </Card>
                    <Card title='Remarks'>
                        <Table pagination={false} columns={columnsRemarks}
                            //  dataSource={tableData?.remarks} 
                            dataSource={Array(tableData)}
                            bordered />

                    </Card>
                </Card>

            </>

        };

        {detailedView === false &&

            <Card
                title="Commercial Documents"
                style={{ width: '100%', top: 0, bottom: 0 }}
                bodyStyle={{ height: '88vh', overflowY: 'scroll' }}
                actions={[
                    <Row justify='space-around'>
                        <Col><Button onClick={() => { cancelHandler(); }}>Cancel</Button></Col>
                        <Col>
                            <Button onClick={() => { submitButtonHandler(); }} type="primary">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                ]}>
                <Form form={formRef} initialValues={initialValues} layout='vertical' autoComplete='off'>
                    <Form.Item name={"bnCmdId"} hidden>
                        <Input></Input>
                    </Form.Item>
                    {/* <Row>
                        <Col
                            xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                        >
                            <Form.Item
                                label={'Select PKKA file to upload'}
                                name={'PKKAFile'}
                                getValueFromEvent={normFile}
                                rules={[{ required: false }]}
                            >
                                <Upload
                                    style={{ width: 130 }}
                                    multiple={false}
                                    listType="text"
                                    showUploadList
                                    {...getUploadProps(0, setFileList, fileList)}

                                >{uploadButton}</Upload>
                            </Form.Item>
                            <ViewFileHandling filesData={initialValues?.cmDocuments.filter((rec) => rec.fileDescription === "PKKA FILE")} required={false} />

                        </Col>

                        <Col
                            xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                        >
                            <Form.Item
                                label={'Select RKBM file to upload'}
                                name={'RKBMFile'}
                                getValueFromEvent={normFile}
                                rules={[{ required: false }]}

                            >
                                <Upload
                                    style={{ width: 130 }}
                                    multiple={false}
                                    listType="text"
                                    showUploadList
                                    {...getUploadProps(1, setFileList, fileList)}

                                >{uploadButton}</Upload>
                            </Form.Item>
                            <ViewFileHandling filesData={initialValues?.cmDocuments.filter((rec) => rec.fileDescription === "RKBMFile FILE")} required={false} />

                        </Col>

                        <Col
                            xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                        >
                            <Form.Item
                                label={'Select IZIN file to upload'}
                                name={'IZINFile'}
                                getValueFromEvent={normFile}
                                rules={[{ required: false }]}

                            >
                                <Upload
                                    style={{ width: 130 }}
                                    multiple={false}
                                    listType="text"
                                    showUploadList
                                    {...getUploadProps(2, setFileList, fileList)}

                                >{uploadButton}</Upload>
                            </Form.Item>
                            <ViewFileHandling filesData={initialValues?.cmDocuments.filter((rec) => rec.fileDescription === "IZINFile FILE")} required={false} />

                        </Col>
                    </Row> */}
                        <Form form={pkkaformRef} layout='vertical' autoComplete='off' style={{margin: '16px 0px'}}>
                            <FileUploadForm key={'pkka'} maxCount={3} layoutType='vertical' formRef={pkkaformRef} initialValues={initialValues.cmDocuments ? initialValues.cmDocuments.filter(doc => doc.featuresRefName === 'Business No PKKA') : []} title='PKKA File Upload'> 

                            </FileUploadForm> 
                        </Form>
                        <Form form={rkbmformRef} layout='vertical' autoComplete='off' style={{margin: '16px 0px'}}>
                            <FileUploadForm key={'rkbm'} maxCount={3} layoutType='vertical' formRef={rkbmformRef} initialValues={initialValues.cmDocuments ? initialValues.cmDocuments.filter(doc => doc.featuresRefName === 'Business No RKBM') : []} title='RKBM File Upload'> 

                            </FileUploadForm>    
                        </Form>                      
                        <Form form={izinformRef} layout='vertical' autoComplete='off' style={{margin: '16px 0px'}}>
                            <FileUploadForm key={'izin'} maxCount={3} layoutType='vertical' formRef={izinformRef} initialValues={initialValues.cmDocuments ? initialValues.cmDocuments.filter(doc => doc.featuresRefName === 'Business No IZIN') : []} title='IZIN File Upload'> 

                            </FileUploadForm>     
                        </Form>                     

                    <Card title='Certificate of Origin(COO)'>
                        <Form.List name='coo'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key}>
                                            <Col
                                                xs={{ span: 22 }}
                                                sm={{ span: 22 }}
                                                md={{ span: 22 }}
                                                lg={{ span: 22 }}
                                                xl={{ span: 22 }}
                                            >
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'COO Number'}
                                                            {...field}
                                                            name={[field.name, 'cooNo']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter COO No'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter COO No'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'Quantity'}
                                                            {...field}
                                                            name={[field.name, 'qty']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Qty'
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} placeholder={'Please Enter Qty'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col><Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'Price'}
                                                            {...field}
                                                            name={[field.name, 'price']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Price'
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} placeholder={'Please Enter Price'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'Third Party Invoice File'}
                                                            {...field}
                                                            name={[field.name, 'thirdPartyInvoiceFile']}
                                                            rules={[
                                                                {
                                                                    required: false, message: 'Please Select Third Party Invoice File'
                                                                }
                                                            ]}
                                                        >
                                                            <Upload
                                                                style={{ width: 130 }}
                                                                multiple={false}
                                                                listType="text"
                                                                showUploadList
                                                                {...getUploadProps(index, setCooFileList, cooFileList)}

                                                            >{uploadButton}</Upload>
                                                        </Form.Item>

                                                        <Form.Item name={[field.name, 'bnCooId']} hidden>
                                                            <Input />
                                                        </Form.Item>

                                                        <ViewFileHandling
                                                            filesData={
                                                                tableData?.coo?.filter((rec) => initialValues?.coo[field.name]?.cooFiles.some((rec2) => rec2.featuresRefId === rec.bnCooId))[0]?.cooFiles?.filter((rec) => rec.fileDescription.split(' ')[0] === "Third")
                                                            }
                                                            required={false} />
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'thirdPartyInvoiceFileId']}
                                                            hidden
                                                        >
                                                            <Input>
                                                            </Input>
                                                        </Form.Item>
                                                        <br></br>
                                                        <br></br>
                                                    </Col>

                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'Port Of Discharge'}
                                                            {...field}
                                                            name={[field.name, 'portOfDischarge']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Port Of Discharge'
                                                                }
                                                            ]}
                                                        >
                                                            <Select
                                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                                                style={{ width: '100%' }} placeholder={'Please Enter Port Of Discharge'}  >
                                                                <Option value=''>Please Select </Option>
                                                                {destination?.map((rec) => { return <Option value={rec.destinationId}>{rec.destinationName}</Option> })}
                                                            </Select>


                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'Upload Document'}
                                                            {...field}
                                                            name={[field.name, 'uploadCooDocument']}
                                                            rules={[
                                                                {
                                                                    required: false, message: 'Please Select Document to upload'
                                                                }
                                                            ]}
                                                        >
                                                            <Upload
                                                                style={{ width: 130 }}
                                                                multiple={false}
                                                                listType="text"
                                                                showUploadList
                                                                {...getUploadProps(index + 1, setCooFileList, cooFileList)}

                                                            >{uploadButton}</Upload>
                                                        </Form.Item>
                                                        <ViewFileHandling filesData={tableData?.coo?.filter((rec) => initialValues?.coo[field.name]?.cooFiles.some((rec2) => rec2.featuresRefId === rec.bnCooId))[0]?.cooFiles?.filter((rec) => rec.fileDescription.split(' ')[0] === "Uploaded")} required={false} />
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'uploadCooDocumentId']}
                                                            hidden
                                                        >
                                                            <Input  >
                                                            </Input>
                                                        </Form.Item>

                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={{ span: 2 }}
                                                sm={{ span: 2 }}
                                                md={{ span: 2 }}
                                                lg={{ span: 2 }}
                                                xl={{ span: 2 }}>
                                                <span style={{
                                                    position: "absolute",
                                                    marginTop: "33px",
                                                    marginLeft: "35px",
                                                }}>
                                                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                                                    </Tooltip>

                                                </span>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                            Add Documents <span>
                                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                                    <PlusCircleOutlined onClick={() => {
                                                        if (5 > fields.length) {
                                                            add()
                                                        } else {
                                                            AlertMessages.getErrorMessage(`You can't add more than ${5} fields`)
                                                        }
                                                    }} />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}></Col>
                                    </Row>
                                </>)}
                        </Form.List>
                    </Card>
                    <Card title='Bill of Lading'>
                        <Form.List name='billOfLading'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key}>
                                            <Col
                                                xs={{ span: 22 }}
                                                sm={{ span: 22 }}
                                                md={{ span: 22 }}
                                                lg={{ span: 22 }}
                                                xl={{ span: 22 }}
                                            >
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'BL No'}
                                                            {...field}
                                                            name={[field.name, 'blNo']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter BL No'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter BL No'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'BL Qty'}
                                                            {...field}
                                                            name={[field.name, 'blQty']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter BL Qty'
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} placeholder={'Please Enter BL Qty'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col><Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'BL Date'}
                                                            {...field}
                                                            name={[field.name, 'blDate']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter BL Date'
                                                                }
                                                            ]}
                                                        >
                                                            <DatePicker placeholder={"Please Enter BL Date"} autoComplete='off' showTime format="YYYY-MM-DD" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'UploadDocument'}
                                                            {...field}
                                                            name={[field.name, 'upLoadBlDocument']}
                                                            rules={[
                                                                {
                                                                    required: false, message: 'Please Select UpLoadDocument'
                                                                }
                                                            ]}
                                                        >
                                                            <Upload
                                                                style={{ width: 130 }}
                                                                multiple={false}
                                                                listType="text"
                                                                showUploadList
                                                                {...getUploadProps(index, setBLFileList, blFileList)}

                                                            >{uploadButton}</Upload>
                                                        </Form.Item>
                                                        {

                                                            <ViewFileHandling filesData={tableData?.billOfLading[field.name]?.billFiles} required={false} />
                                                        }


                                                        <Form.Item hidden name={[field.name, 'bnBlOfLadingFileId']} >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item name={[field.name, 'bnBlOfLadingId']} hidden>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >

                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={{ span: 2 }}
                                                sm={{ span: 2 }}
                                                md={{ span: 2 }}
                                                lg={{ span: 2 }}
                                                xl={{ span: 2 }}>
                                                <span style={{
                                                    position: "absolute",
                                                    marginTop: "33px",
                                                    marginLeft: "35px",
                                                }}>
                                                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                                                    </Tooltip>

                                                </span>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                            Add Documents <span>
                                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                                    <PlusCircleOutlined onClick={() => {
                                                        if (5 > fields.length) {
                                                            add()
                                                        } else {
                                                            AlertMessages.getErrorMessage(`You can't add more than ${5} fields`)
                                                        }
                                                    }} />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}></Col>
                                    </Row>
                                </>)}
                        </Form.List>
                    </Card>
                    <Card title='Export Tax (PEB)'>
                        <Form.List name='exportTax'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key}>
                                            <Col
                                                xs={{ span: 22 }}
                                                sm={{ span: 22 }}
                                                md={{ span: 22 }}
                                                lg={{ span: 22 }}
                                                xl={{ span: 22 }}
                                            >
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'Supplier'}
                                                            {...field}
                                                            name={[field.name, 'supplier']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Select Supplier'
                                                                }
                                                            ]}
                                                        >
                                                            <Select
                                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                                                showSearch allowClear placeholder={"Please Select Supplier"} style={{ width: "100%" }}>

                                                                <Option value="">Please Select</Option>
                                                                {suppliers.map(item => { return <Option value={item.bpId}>{item.bpName}</Option> })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'PEB No'}
                                                            {...field}
                                                            name={[field.name, 'pebNo']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter PEB NO'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter  PEB NO'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'PEB Date'}
                                                            {...field}
                                                            name={[field.name, 'pebDate']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter PEB Date'
                                                                }
                                                            ]}
                                                        >
                                                            <DatePicker placeholder={"Please Enter PEB Date"} autoComplete='off' showTime format="YYYY-MM-DD" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                                        <Form.Item
                                                            label={'ID Billing'}
                                                            {...field}
                                                            name={[field.name, 'idBilling']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Id Billing'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter  Id Billing'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}>
                                                        <Form.Item
                                                            label={'Qty'}
                                                            {...field}
                                                            name={[field.name, 'quantity']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Quantity'
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} placeholder={'Please Enter  Quantity'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}>
                                                        <Form.Item
                                                            label={'Export Tax Per Mt'}
                                                            {...field}
                                                            name={[field.name, 'exportTaxPerMt']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter ExportTaxPerMt'
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} placeholder={'Please Enter  ExportTax Per Mt'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'UploadDocument'}
                                                            {...field}
                                                            name={[field.name, 'upLoadPebDocument']}
                                                            rules={[
                                                                {
                                                                    required: false, message: 'Please Select UpLoadDocument'
                                                                }
                                                            ]}
                                                        >
                                                            <Upload
                                                                style={{ width: 130 }}
                                                                multiple={false}
                                                                listType="text"
                                                                showUploadList
                                                                {...getUploadProps(index, setPEBFileList, pebFileList)}

                                                            >{uploadButton}</Upload>
                                                        </Form.Item>
                                                        <ViewFileHandling
                                                            filesData={tableData?.exportTax[field.name]?.exportTaxFiles}
                                                            required={false} />

                                                        <Form.Item name={[field.name, 'bnExportxFileId']} hidden>
                                                            <Input />
                                                        </Form.Item>

                                                        <Form.Item name={[field.name, 'bnExportxId']} hidden>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >

                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={{ span: 2 }}
                                                sm={{ span: 2 }}
                                                md={{ span: 2 }}
                                                lg={{ span: 2 }}
                                                xl={{ span: 2 }}>
                                                <span style={{
                                                    position: "absolute",
                                                    marginTop: "33px",
                                                    marginLeft: "35px",
                                                }}>
                                                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                                                    </Tooltip>

                                                </span>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                            Add Documents <span>
                                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                                    <PlusCircleOutlined onClick={() => {
                                                        if (5 > fields.length) {
                                                            add()
                                                        } else {
                                                            AlertMessages.getErrorMessage(`You can't add more than ${5} fields`)
                                                        }
                                                    }} />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}></Col>
                                    </Row>
                                </>)}
                        </Form.List>
                    </Card>

                    <Card title='Notify party'>
                        <Form.List name='notifyParty'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key} >
                                            <Col
                                                xs={{ span: 22 }}
                                                sm={{ span: 22 }}
                                                md={{ span: 22 }}
                                                lg={{ span: 22 }}
                                                xl={{ span: 22 }}
                                            >
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'Name'}
                                                            {...field}
                                                            name={[field.name, 'partyName']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Select Name'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter Name'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'Phone Number'}
                                                            {...field}
                                                            name={[field.name, 'phoneNo']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Phone Number'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter  Phone Number'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'Address'}
                                                            {...field}
                                                            name={[field.name, 'partyAddress']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Address'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter Address'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    
                                                </Row>
                                                
                                            </Col>
                                            <Col xs={{ span: 2 }}
                                                sm={{ span: 2 }}
                                                md={{ span: 2 }}
                                                lg={{ span: 2 }}
                                                xl={{ span: 2 }}>
                                                <span style={{
                                                    position: "absolute",
                                                    marginTop: "33px",
                                                    marginLeft: "35px",
                                                }}>
                                                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                                                    </Tooltip>

                                                </span>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                            Add Notify Party <span>
                                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                                    <PlusCircleOutlined onClick={() => {
                                                        if (5 > fields.length) {
                                                            add()
                                                        } else {
                                                            AlertMessages.getErrorMessage(`You can't add more than ${5} fields`)
                                                        }
                                                    }} />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}></Col>
                                    </Row>
                                </>)}
                        </Form.List>
                    </Card>
                    
                    <Card title='Insurance'>
                        <Form.List name='insurance'>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={field.key}
                                        style={{
                                            border: '1px solid #d9d9d9', 
                                            padding: '16px', 
                                            borderRadius: '4px', 
                                            marginBottom: '16px'
                                        }}
                                        >
                                            <Col
                                                xs={{ span: 22 }}
                                                sm={{ span: 22 }}
                                                md={{ span: 22 }}
                                                lg={{ span: 22 }}
                                                xl={{ span: 22 }}
                                            >
                                                <Row>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                                    >
                                                        <Form.Item
                                                            label={'Insurance Vendor'}
                                                            {...field}
                                                            name={[field.name, 'insVendorName']}
                                                        >
                                                            <Select
                                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                                                showSearch allowClear placeholder={"Please Select Insurance Vendor"} style={{ width: "100%" }}>

                                                                <Option value="">Please Select</Option>
                                                                {vendorsData.map(item => { return <Option value={item.bpId} key={item.bpId}>{item.bpName}</Option> })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                                    >
                                                        <Form.Item
                                                            label={'Policy number'}
                                                            {...field}
                                                            name={[field.name, 'policyNumber']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please Enter Policy number'
                                                                }
                                                            ]}
                                                        >
                                                            <Input placeholder={'Please Enter  Policy number'} autoComplete='off' />
                                                        </Form.Item>
                                                    </Col>
                                                    </Row>
                                                    <Row gutter={18}>
                                                    <Col>
                                                        <Form.Item name={[field.name, 'priceType']}
                                                            label="Price Type"
                                                            // rules={[{ required: true, message: "Please Select An Option" }]} 
                                                            >
                                                        <Radio.Group onChange={(e) => handlePriceType(e, field)}>
                                                            {Object.keys(PriceTypeEnum).map(priceTypeKey => {
                                                                return <Radio key={priceTypeKey} value={PriceTypeEnum[priceTypeKey]}>{PriceTypeEnum[priceTypeKey]}</Radio>
                                                            })}
                                                        </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    {/* formRef.getFieldValue(['insurance', index, 'priceType']) */}
                                                    {formRef.getFieldValue(['insurance', field.name, 'priceType']) === PriceTypeEnum.LUMP_SUM && (
                                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                                                        <Form.Item
                                                                label={"Insurance Price(Lumpsum)"}
                                                                {...field}
                                                                name={[field.name, 'insurancePrice']}
                                                                rules={[
                                                                    ...classValidator.onlyNumeric()
                                                                ]}
                                                                >
                                                                <Input  addonAfter={suffixSelector(field.name, 'priceCurrency')} placeholder={"Insurance Price"} />
                                                        </Form.Item>
                                                    </Col> 
                                                    )}
                                                    {formRef.getFieldValue(['insurance', field.name, 'priceType']) === PriceTypeEnum.PER_MT && (
                                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                                                        <Form.Item
                                                                label={"Insurance Amount(PMT)"}
                                                                name={[field.name, 'insuranceAmount']}
                                                                rules={[
                                                                    ...classValidator.onlyNumeric()
                                                                ]}
                                                                >
                                                                <Input  addonAfter={suffixSelector(field.name, 'amountCurrency')} placeholder={"Insurance Amount"} />
                                                        </Form.Item>
                                                    </Col>
                                                    )}
                                                    </Row>
                                            </Col>
                                            <Col xs={{ span: 2 }}
                                                sm={{ span: 2 }}
                                                md={{ span: 2 }}
                                                lg={{ span: 2 }}
                                                xl={{ span: 2 }}>
                                                <span style={{
                                                    position: "absolute",
                                                    marginTop: "33px",
                                                    marginLeft: "35px",
                                                }}>
                                                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                                                    </Tooltip>

                                                </span>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                            Add Insurance <span>
                                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                                    <PlusCircleOutlined onClick={() => {
                                                        if (5 > fields.length) {
                                                            add()
                                                        } else {
                                                            AlertMessages.getErrorMessage(`You can't add more than ${5} fields`)
                                                        }
                                                    }} />
                                                </Tooltip>
                                            </span>
                                        </Col>
                                        <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}></Col>
                                    </Row>
                                </>)}
                        </Form.List>
                    </Card>




                    <Card>
                        <Row>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
                                <Form.Item label="Remarks"
                                    name="remarks">
                                    <Input.TextArea />
                                </Form.Item></Col>
                        </Row>
                    </Card>
                </Form>
            </Card>
        }
        <Row>
            <Col offset={21}>
                <Button type='primary' onClick={() => setDetailedView(!detailedView)}>{detailedView ? "Edit" : "View"}</Button>

            </Col>

        </Row>
    </>

}

export default BNCommercialPage;